<mat-toolbar>
  <mat-toolbar-row>
    <!-- Left Side: Logo -->
    <div class="left-side">
      <button mat-icon-button (click)="toggleSideBar()">
        <mat-icon>menu</mat-icon>
        <img src="./assets/tl-logo.svg" alt="TL Logo">
      </button>
    </div>

    <span class="spacer"></span>

    <!-- Right Side: Wallet and Balance Information -->
    <div class="right-side">
    <div id="wallet-ui">
        <!-- Download TL Wallet Button -->
        <button
            id="download-wallet-btn"
            class="download-wallet-btn"
            *ngIf="!walletAddress"
            (click)="openWalletDownload()"
        >
            Download TL Wallet
        </button>

        <!-- Connect Wallet Button -->
        <button
            id="connectWalletButton"
            class="connect-button"
            *ngIf="!walletAddress"
            (click)="connectWallet()"
        >
            Connect Wallet
        </button>

        <!-- Wallet Info Display -->
        <div id="wallet-info" *ngIf="walletAddress" class="wallet-address-display">
            Connected: <span id="connectedAddress">{{ walletAddress | slice: 0:6 }}...{{ walletAddress | slice: -6 }}</span>
        </div>
    </div>
</div>

  </mat-toolbar-row>
</mat-toolbar>

<div class="nav-container">
    <nav mat-tab-nav-bar mat-align-tabs="center">
        <a
            *ngFor="let route of mainRoutes"
            mat-tab-link
            (click)="navigateTo(route)"
            [active]="selectedRoute === route"
            [disabled]="route.disabled"
        >
            {{ route.name }}
        </a>
    </nav>
</div>
